import React from 'react';
import { connect } from 'react-redux';
import { setPlayers as setPlayersAction } from './NewGame.slice';
import { createGame as createGameAction } from '../data/Data.slice';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';

const NewGame = ({ players, setPlayers, createGame }) => {
  const handleSubmit = e => {
    e.preventDefault();
    createGame(players);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Form.Label className="align-middle">Anzahl Spieler:</Form.Label>
        </Col>
        <Col>
          <Form.Control
            className="text-end"
            type="number"
            value={players}
            onChange={e => setPlayers(e.target.value)}
          />
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = state => ({
  players: state.NewGame.players,
});

const mapDispatchToProps = dispatch => ({
  setPlayers: players => dispatch(setPlayersAction(players)),
  createGame: players => dispatch(createGameAction(players)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewGame);
