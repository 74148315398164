import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Badge, Col, Form, Row, Table } from 'react-bootstrap';
import {
  setPlayerActual as setPlayerActualAction,
  setPlayerBet as setPlayerBetAction,
  setPlayerName as setPlayerNameAction,
} from './Data.slice';

const Data = ({
  players,
  course,
  setPlayerName,
  setPlayerBet,
  setPlayerActual,
}) => {
  const points = players.map((name, player) => ({
    name,
    points: course
      .map(data => data[player])
      .filter(
        data =>
          !Number.isNaN(parseInt(data.bet)) &&
          !Number.isNaN(parseInt(data.actual))
      )
      .reduce(
        (
          acc,
          data // todo: Math.max as optional
        ) =>
          Math.max(
            0,
            (acc +=
              data.bet === data.actual
                ? 20 + data.actual * 10
                : -10 * Math.abs(data.bet - data.actual))
          ),
        0
      ),
  }));

  const leaderboard = points.slice().sort((a, b) => b.points - a.points);
  const placeColors = ['#d4af37', '#c0c0c0', '#cd7f32'];

  return (
    <Fragment>
      <Row>
        {leaderboard.map(({ name }, i) => (
          <Col key={i}>
            <span className="badge" style={{ backgroundColor: placeColors[i] ?? '#333' }}>
              #{i + 1}
            </span>&nbsp;{name}
          </Col>
        ))}
      </Row>
      <Table>
        <thead>
          <tr>
            <th key="-1" />
            {players.map((name, i) => (
              <Fragment key={i}>
                <th>
                  <Form.Control
                    value={name}
                    onChange={e => setPlayerName(i, e.target.value)}
                  />
                </th>
                <th className="align-middle">{points[i].points}&nbsp;Punkte</th>
              </Fragment>
            ))}
          </tr>
          <tr>
            <th>Runde</th>
            {players.map((_, i) => (
              <Fragment key={i}>
                <td>Geschätzt</td>
                <td>Erhalten</td>
              </Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {course.map((data, i) => (
            <tr key={i}>
              <td className="text-end">{i + 1}</td>
              {players.map((_, j) => (
                <Fragment key={j}>
                  <td>
                    <Form.Control
                      value={data[j].bet}
                      onChange={e => setPlayerBet(j, i, e.target.value)}
                    />
                  </td>
                  <td>
                    <Form.Control
                      value={data[j].actual}
                      onChange={e => setPlayerActual(j, i, e.target.value)}
                    />
                  </td>
                </Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  players: state.Data.players,
  course: state.Data.course,
});

const mapDispatchToProps = dispatch => ({
  setPlayerName: (player, name) =>
    dispatch(setPlayerNameAction({ id: player, name })),
  setPlayerBet: (player, round, bet) =>
    dispatch(setPlayerBetAction({ id: player, round, bet })),
  setPlayerActual: (player, round, actual) =>
    dispatch(setPlayerActualAction({ id: player, round, actual })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Data);
