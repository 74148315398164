import { configureStore } from "@reduxjs/toolkit";
import reducer from './reducer';

const data = window.localStorage.getItem("wiz-data");
const preloadedState = data ? JSON.parse(data) : {};

const store = configureStore({
    reducer,
    preloadedState,
});

store.subscribe(() => {
    window.localStorage.setItem("wiz-data", JSON.stringify(store.getState()));
});

export default store;
