import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  players: 0,
};

const slice = createSlice({
  name: 'NewGame',
  initialState,
  reducers: {
    setPlayers: (state, { payload: players }) => {
      state.players = players;
    },
  },
});

export const { setPlayers } = slice.actions;
export default slice.reducer;
