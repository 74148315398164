import { combineReducers } from 'redux';
import DataSlice from '../features/data/Data.slice';
import NewGameSlice from '../features/new-game/NewGame.slice';

const reducers = combineReducers({
    NewGame: NewGameSlice,
    Data: DataSlice,
});

export default reducers;
