import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  players: [],
  course: [],
};

const slice = createSlice({
  name: 'Data',
  initialState,
  reducers: {
    createGame: (state, { payload: players }) => {
      const playerNames = state.players?.slice(0, players) ?? [];
      const rounds = 60 / players;

      for (let i = playerNames.length; i < players; i++) {
        playerNames.push(`Spieler ${i + 1}`);
      }

      const course = new Array(rounds).fill(
        playerNames.map(() => ({ bet: '', actual: '' }))
      );

      state.players = playerNames;
      state.course = course;
    },
    setPlayerName: (state, { payload: { id, name } }) => {
      state.players[id] = name;
    },
    setPlayerBet: (state, { payload: { id, round, bet } }) => {
      state.course[round][id].bet = bet;
    },
    setPlayerActual: (state, { payload: { id, round, actual } }) => {
      state.course[round][id].actual = actual;
    },
  },
});

export const { createGame, setPlayerName, setPlayerBet, setPlayerActual } =
  slice.actions;
export default slice.reducer;
