import Container from "react-bootstrap/Container";
import store from "./app/store";
import { Provider as StoreProvider } from "react-redux";
import NewGame from "./features/new-game/NewGame";
import Data from "./features/data/Data";

const App = () => (
  /*
  3 players: 20 rounds
  4 players: 15
  5 players: 12
  6 players: 10

  60 (amount of cards) / playerCount = rounds

  on wrong guess   : -10 * |guess - actual|
  on correct guess :  20 +  guess * 10

  required input: guessedPiles, actualPiles
  */
  <StoreProvider store={store}>
    <Container className="p-3" >
      <NewGame />
      <br/>
      <Data />
    </Container>
  </StoreProvider>
);

export default App;
